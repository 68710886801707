import React, { Component, Fragment } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import { Section } from '../components/section'
import Layout from '../components/layout'
import FullWidthSection from '../components/full-width-section'
import TextSection from '../components/text-section'
import Grid from '../components/grid'
import CircleGrid from '../components/circle-grid'
import CircleGridSection from '../components/circle-grid-section'
import UList from '../components/list'
import SEO from '../components/seo'
import { SpacedHr, HalfSpacedHr } from '../components/spaced-hr'

import { Collapse, CardBody, Card, CardHeader, Row } from 'reactstrap'

const faqs = [
  {
    q: 'Are you a nonprofit?',
    a:
      'Currently, we are a group of concerned business owners organizing to support other business owners and our larger Somali-American community, We will be exploring the best path forward through fiscal sponsorship and/or seeking 501c3 status. Sign up to our email list to stay in the loop.',
  },
  {
    q:
      'What kind of support is there for small businesses struggling from the Covid-19 pandemic?',
    a:
      'We will be collecting and posting all resources and opportunities for small business in our Covid-19 News Page</a> ',
  },
  // {
  //   q: 'Whats ',
  //   a:
  //     'If we default on any terms of our agreement, you have the right to end the agreement. We will return the property in excellent condition.',
  // },
]

const H1 = styled.h1`
  margin: 0;
  color: #fff;
`

const Row1 = styled.div`
  max-width: 80rem;
  width: 100%;
  margin: 0rem auto;
  padding: 1.5rem 24px 0 24px;

  @media (max-width: 768px) {
    margin: 0rem auto 3rem;
    padding: 0;
  }
`

const PageHeader = styled.div`
  /* background: red; */
  margin: 0 -24px;
`
const PageSection = styled.div`
  padding: 1rem 0;
  background: #efefef;
  margin: 0 -24px;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

const PageTitleSection = styled.div`
  background: #0094d9;
  padding: 20px 24px;
  margin: 0 -25px -17px;
  @media (max-width: 767px) {
    padding: 20px 24px 10px;
    display: flex;
    background: #0094d9;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    height: 100%;
    margin: 13px -12px 0;
  }
`

class FAQ extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = { collapse: 0 }
  }

  toggle(e) {
    let event = e.target.dataset.event
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event),
    })
  }

  render() {
    const { store } = this.props.data
    const { collapse } = this.state

    return (
      <Fragment>
        <Layout>
          <SEO title="Somali American Business Association" />
          {/* <PageSection> */}
          <Row1>
            <PageTitleSection>
              <H1>Frequently Asked Questions</H1>
              {/*<h6>These property owners are currently earning substantially higher*/}
              {/*rental income with BV Rental Networks.</h6>*/}
            </PageTitleSection>
            {/* Header Image */}
            <FullWidthSection fluidImage={store.childImageSharp.fluid} />
            <SpacedHr />

            {faqs.map((faq, i) => {
              return (
                <Card style={{ marginBottom: '1rem' }} key={i}>
                  <CardHeader onClick={this.toggle} data-event={i}>
                    {faq.q}
                  </CardHeader>
                  <Collapse isOpen={collapse === i}>
                    <CardBody>{faq.a}</CardBody>
                  </Collapse>
                </Card>
              )
            })}
          </Row1>
          {/* </PageSection> */}
        </Layout>
      </Fragment>
    )
  }
}

export default FAQ

export const faqQuery = graphql`
  query faqQuery {
    store: file(relativePath: { eq: "store.jpg" }) {
      ...fluidLargeImage
    }
  }
`
